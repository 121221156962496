<template>
  <label button-list @click="$emit('click')">
    <color-button type="white" @click="$emit('click')">
      <svg-angle />
    </color-button>
    <span>목록보기</span>
  </label>
</template>
<script>
import ColorButton from '@shared/components/common/ColorButton.vue';
import SvgAngle from '@shared/graphics/svg-angle.vue';

export default {
  components: { SvgAngle, ColorButton },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[button-list] { .block;.h(40);
  > [color-button] {.wh(40, 40);.mr(8);}
  > [color-button] > svg { .t-r(90deg);}
  > span {.c(#787781);.fs(14);.lh(20);}
}
</style>
