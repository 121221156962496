<template>
  <button button-icon-ghost
          :data-type="type"
          :aria-label="`button-${type}`"
          @mouseenter="() => (hover = true)"
          @mouseleave="() => (hover = false)"
          @click="() => $emit('click')"
  >
    <img v-show="!hover && active" :src="iconActiveUrl" :alt="`icon-${type}-active`"/>
    <img v-show="hover && active" :src="iconHoverUrl" :style="{ opacity: 0.5 }" :alt="`icon-${type}-hover`"/>
    <img v-show="!active" :src="iconUrl" class="hoverable" :alt="`icon-${type}`"/>
  </button>
</template>

<script>
export default {
  data: () => ({
    hover: false,
  }),
  props: {
    type: {
      type: String,
      default: 'thumbsup',
      validator(val) {
        return ['thumbsup', 'bookmark', 'share'].findIndex((v) => v === val) !== -1;
      },
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getAsset(url) {
      return `/img/icon/coaching/${url}`;
    }
  },
  computed: {
    iconHoverUrl() {
      return this.getAsset(`icon-ghost-${this.type}-blue.svg`);
    },
    iconActiveUrl() {
      return this.getAsset(`icon-ghost-${this.type}-active.svg`);
    },
    iconUrl() {
      return this.getAsset(`icon-ghost-${this.type}.svg`);
    },
  },
};
</script>

<style lang="less">
[button-icon-ghost] {
  &[data-type='thumbsup'] > img {
    width: 18.33px;
  }
  &[data-type='bookmark'] > img {
    width: 14px;
  }
  &[data-type='share'] > img {
    width: 18px;
  }
  width: 35px;
  height: 35px;
  display: inline-block;
  border: none;
  background-color: transparent;
  border-radius: 10px;
  &:active {
    background-color: white;
    opacity: 0.5;
  }
  .hoverable:hover {
    opacity: 0.5;
  }
}
</style>
