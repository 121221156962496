<template>
  <div question-detail>
    <ul class="tags">
      <tag v-for="category in categories" :key="`${category.groupName}-${category.value}`" @click="() => onTagClick(category)">{{ category.text }}</tag>
    </ul>
    <div class="top">
      <category-icon :category="category" />
      <h1 class="title">{{ title }}</h1>
    </div>
    <p class="user-info">
      <em class="user-nickname" v-if="usePrivateName">***</em> <em class="user-nickname" v-else>{{ nickname }}</em>
      <lol-rank-text :value="rankCode" class="user-rank" />
    </p>
    <pre class="content">{{ content }}</pre>
    <cdn-img v-for="(imageUrl, idx) in imageUrls" :src="imageUrl" :key="`imageUrl-${idx}`" class="img-preview" alt="image-preview" @click="() => onImageExamine(imageUrl)" />
    <div class="bottom">
      <span class="created-date">{{ $fromNow(createdDatetime) }} 질문 작성됨</span>
      <button-icon-ghost type="bookmark" :active="hasScraped" @click="onScrap" />
      <button-icon-ghost type="share" @click="onShare" />
    </div>
  </div>
</template>

<script>
import CdnImg from '@shared/components/common/CdnImg.vue';
import { cdnSrc } from '@shared/utils/cdnUtils';
import Tag from '@/views/components/coaching/Tag.vue';
import CategoryIcon from '@/views/components/coaching/question/CategoryIcon.vue';
import ButtonIconGhost from '@/views/components/coaching/question-detail/ButtonIconGhost.vue';
import ImageModal from '@/views/components/common/modal/ImageModal.vue';
import LolRankText from '@/views/components/coaching/LolRankText.vue';

export default {
  components: { LolRankText, CdnImg, ButtonIconGhost, CategoryIcon, Tag },
  lexicon: 'coaching',
  props: {
    question: {
      type: Object,
      default: () => ({}),
    },
    isCoach: {
      type: Boolean,
      default: false,
    },
  },
  metaInfo() {
    let ogTitle = this.title;
    this.categories.forEach(each => {
      if (each.value !== "ALL") {
        ogTitle += ` [${each.text}]`;
      }
    });
    return {
      title: ogTitle,
      meta: [
        { vmid: 'title', content: ogTitle },
        { vmid: 'description', content: this.content },
        { vmid: 'url', content: this.$t('meta.url') + this.$router.currentRoute.path },
        { vmid: 'keywords', content: this.categories.map(each => each.text) },
        { vmid: 'site_name', content: this.$t('meta.siteName') },
        { vmid: 'type', content: this.$t('meta.type') },
        { vmid: 'image', content: this.$t('meta.image') },
      ],
    };
  },
  computed: {
    q() {
      return /** @type{Question} */ this.question || {};
    },
    boardWriteId() {
      return this.q?.boardWriteId || '';
    },
    title() {
      return this.q?.title || '';
    },
    content() {
      return this.q?.content || '';
    },
    categories() {
      return /** @type{QuestionCategory[]} */ this.q?.categories || [];
    },
    category() {
      return /** @type{QuestionCategory} */this.categories[0] || {};
    },
    rankCode() {
      return this.q?.rank?.code || '';
    },
    nickname() {
      return this.q?.user?.nickname || '';
    },
    imageUrls() {
      return /** @type{Question.imageUrls} */ this.q?.imageUrls || [];
    },
    createdDatetime() {
      return this.q?.createdDatetime || 0;
    },
    hasScraped() {
      return this.q?.hasScraped || false;
    },
    usePrivateName() {
      return this.q?.usePrivateName || false;
    },

  },
  methods: {
    onShare() {
      this.$emit('share', this.boardWriteId);
    },
    onScrap() {
      if (this.hasScraped) {
        this.$emit('cancel-scrap', this.boardWriteId);
        return;
      }
      this.$emit('scrap', this.boardWriteId);
    },
    onImageExamine(imageUrl) {
      this.$modal(ImageModal, { img: cdnSrc(imageUrl) });
    },
    onTagClick(category) {
      let query = {};
      if (category?.groupName === 'LOL_LANE') query = { 'lol-lane': category?.value };
      if (category?.groupName === 'LOL_CHAMPION') query = { 'lol-champ': category?.value };
      if (this.isCoach) {
        this.$router.push({ path: '/coaching/coach-center', query });
        return;
      }
      this.routeName('CoachQuestions', { gameId: 'LOL' }, query);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[question-detail] {.bgc(#fff);.br(16);box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);.p(32, 32, 32, 32);.noto;
  > .top { .flex;.flex-ai(center);}
  > .top > .title { .c(#1f2226);.fs(18);.lh(26.64);.ml(8);.max-w(820);.ellipsis(10);}
  > .tags { .mb(16)}
  > .tags [tag] {.pointer;}
  > .user-info > * { display: inline-block;.fs(12);.lh(17.76);.mb(8);}
  > .user-info > .user-nickname { .c(#191919);.bold;.mr(4);.ellipsis;.w(100%);}
  > .user-info > .user-rank { .c(@c-text-gray);.regular; }
  > .content {.c(#191919);.fs(14);.lh(20.72);white-space: pre-line;.max-w(950);text-overflow: ellipsis;}
  > .img-preview { .max-w(480);.max-h(268);}
  > .bottom {.flex;}
  > .bottom > .created-date { .c(@c-text-dark);.fs(12);.lh(17.76);.mt(20);flex-grow: 1;}
  @media (@tp-down) {
    .p(32, 20, 32, 20);
    > .top > .title { .w(80%); }
  }
}
</style>
