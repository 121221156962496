<template>
  <color-button @click="$emit('click')" button-lesson :disabled="disabled" type="dark">
    <span>수업 상담</span>
    <img src="/img/icon/icon-arrow-right-white.svg" />
  </color-button>
</template>
<script>
import ColorButton from '@shared/components/common/ColorButton.vue';

export default {
  components: { ColorButton },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[button-lesson] {.flex;.flex-jc(center);.flex-ai(center);
  > span {.mr(8);}
  > img { .wh(16);}
  @media (@tp-down) {
    > span {.hide}
  }
}
</style>
