<template>
  <Modal image-modal @close="$emit('close')">
    <div class="image-holder">
      <img :src="options.img" />
    </div>
  </Modal>
</template>

<script>
import Modal from '@shared/components/common/Modal.vue';

export default {
  name: 'ImageModal',
  components: { Modal },
  props: {
    options: Object,
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[image-modal] { .z(1030);
  .panel.large { .w(90%); .max-w(800);}
  .scroll-area { .pb(40) !important; }
  .image-holder { .rel; .wh(100%); .min-h(500);
    img { .wh(100%); object-fit: contain; object-position: center; .lt(50%, 50%); .t-xyc; .abs; }
  }
}

</style>
