<template>
  <div input-answer :class="{['input-visible']:inputVisible, disabled }">
    <ul class="input-tip" v-show="value === '' && !disabled" @click="onTipClick">
      <li>답변 첫 줄은 가장 많은 화면에 노출됩니다. 짧고 강렬한 본론으로 답변을 시작해보세요! 다른 답변들과 차별될수록 조회 수가 올라갑니다.</li>
      <li>명예 훼손, 무단 광고 등 서비스 운영 정책 위반의 책임은 게시자에게 있습니다.</li>
      <li>개인/법인 실명, 전화번호, 주소 등의 개인정보가 포함되지 않았는지 다시 한번 확인해주세요.</li>
    </ul>
    <p class="input-info">{{ infoText }}</p>
    <textarea ref="textarea" @blur="onBlur" @focus="onFocus" :placeholder="placeholder" :value="value" @input="onInput" :disabled="disabled" />
    <div class="control" v-show="focused && !disabled">
      <color-button type="white" @click="onCancel">취소</color-button>
      <color-button type="dark" @click="onSave" v-if="!editing">저장</color-button>
      <color-button type="dark" @click="onSave" v-else>수정 완료</color-button>
    </div>
  </div>
</template>

<script>
import ColorButton from '@shared/components/common/ColorButton.vue';

export default {
  components: { ColorButton },
  data: () => ({
    focused: false,
  }),
  props: {
    value: {
      type: String,
      default: '',
    },
    minLength: {
      type: Number,
      default: 10,
    },
    maxLength: {
      type: Number,
      default: 2000,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    editing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
    textLength() {
      return this.value?.length || 0;
    },
    infoText() {
      return `${this.textLength}/${this.maxLength} (최소 ${this.minLength}자)`;
    },
    inputVisible() {
      if (this.disabled) return false;
      if (this._value !== '') return true;
      return this.focused;
    },
    placeholder() {
      if (this.disabled) return '코치는 한 번의 댓글만 작성 가능합니다';
      return '댓글 추가';
    },
  },
  methods: {
    onFocus() {
      this.focused = true;
      this.$nextTick(() => {
        this.$refs.textarea.focus();
        this.$emit('focus');
      });
    },
    onBlur() {
      if (this.value === '' && !this.editing) {
        this.focused = false;
        this.$emit('blur');
      }
    },
    onInput(ev) {
      this._value = ev.target.value;
    },
    onTipClick() {
      this.$refs.textarea.focus();
    },
    onCancel() {
      this.$emit('cancel');
    },
    onSave() {
      this.$emit('save');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[input-answer] {.rel;
  > textarea {.w(100%);.bgc(#fff);.fs(14);}
  > .control {text-align: right;
    [color-button] + [color-button] {.ml(8)}
  }
}
[input-answer].disabled {
  > textarea {.h(40);.bgc(#dcdce5);box-shadow: inset 0 -1px 1px 0px #c4c4c4;.br(12);}
}
[input-answer]:not(.input-visible) {
  .input-tip {.hide}
  .input-info {.hide}
  > textarea {.h(40);.br(12);.p(10, 12, 9, 12);box-shadow: inset 0 -1px 1px 0px #d5d5de;}
}
[input-answer].input-visible {
  > textarea {.br(16);.p(16, 16, 42, 16);.scroll;.h(265)}
  > textarea::placeholder {.o(0)}
  .input-tip {.abs;.p(10, 12, 9, 12);.c(#a4a3ae);}
  .input-tip > li { .ml(13);.rel;.lh(20.72);.fs(14)}
  .input-tip > li::before {content: '';.bgc(#a4a3ae);.wh(4, 4);.br(100%);.abs;.l(-12);.t(8)}
  .input-info {.abs;.b(56);.r(16);.c(#a4a3ae);.fs(14);.lh(14)}
}
[input-answer].input-visible.error {
  > textarea {border: 1px solid #f00}
  .input-info {.c(#f00);}
}

</style>
