<template>
  <div question-answer :class="{shared: shared === true, ['no-share']: shared === false}">
    <div class="top">
      <avatar :info="userInfo" :defaultImgType="'fruit'" dimension="100x100" userProfile />
      <div class="coach-info">
        <em class="coach-nickname">{{ nickname }}</em>
        <lol-rank-text :value="rankCode" class="coach-rank" tag="p" />
      </div>
      <button-lesson @click="onConsult" />
    </div>
    <pre class="content" v-show="!edit" @click="onContentClick" ref="content">
      {{ content }}
    </pre>
    <ValidationObserver ref="validator">
      <ValidationProvider rules="required|lengthMin:10|lengthMax:2000" name="questionAnswer" v-slot="{errors}">
        <input-answer v-show="edit && editable" v-model="editText" editing ref="inputAnswer" @cancel="onCancelEdit" @save="onSaveEdit" :class="{error:errors.length >= 1}" />
      </ValidationProvider>
    </ValidationObserver>
    <div class="bottom">
      <p class="created-time">{{ createdDatetimeText }} 답변 작성됨</p>
      <label class="likes" :class="{hasLiked}">
        <button-icon-ghost type="thumbsup" :active="hasLiked" @click="onLike" />
        <span class="description">도움이 되었어요</span>
        <span>{{ like }}</span>
      </label>
      <button-icon-ghost type="share" @click="onShare" />
    </div>
  </div>
</template>

<script>
import Avatar from '@shared/components/common/Avatar.vue';
import ButtonLesson from '@/views/components/coaching/question-detail/ButtonLesson.vue';
import ButtonIconGhost from '@/views/components/coaching/question-detail/ButtonIconGhost.vue';
import InputAnswer from '@/views/components/coaching/question-detail/InputAnswer.vue';
import coach from '@/mixins/coach';
import LolRankText from '@/views/components/coaching/LolRankText.vue';

export default {
  components: { LolRankText, InputAnswer, ButtonIconGhost, ButtonLesson, Avatar },
  mixins: [coach],
  data: () => ({
    edit: false,
    editText: '',
  }),
  props: {
    answer: {
      type: Object,
      default: () => ({}),
    },
    shared: {
      // 세 가지 상태를 사용하므로 유의할 것: 피그마 참조
      // 1 공유 함(true), 2 공유 안함(false), 3 페이지에 공유 정보가 안 들어감(null)
      type: [Boolean, null],
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    a() {
      return /** @type{Answer} */ this.answer || {};
    },
    boardWriteId() {
      return this.a?.boardWriteId || '';
    },
    boardCommentId() {
      return this.a?.boardCommentId || '';
    },
    userInfo() {
      return this.a?.coach?.user || {};
    },
    nickname() {
      return this.a?.coach?.user?.nickname || '';
    },
    rankCode() {
      return this.a?.coach?.rank?.code || '';
    },
    content() {
      return this.a?.content || '';
    },
    createdDatetime() {
      return this.a?.createdDatetime || 0;
    },
    createdDatetimeText() {
      return this.$fromNow(this.createdDatetime);
    },
    hasLiked() {
      return this.a?.hasLiked || false;
    },
    like() {
      return this.a?.like || 0;
    },
  },
  methods: {
    onShare() {
      this.$emit('share', this.boardWriteId, this.boardCommentId);
    },
    async onLike() {
      if (!this.$store?.getters['auth/isLogin']) {
        await this.$services.auth.oAuthLogin(true);
        return;
      }
      if (!this.hasLiked) {
        this.$emit('like', this.boardWriteId, this.boardCommentId);
        return;
      }
      this.$emit('unlike', this.boardWriteId, this.boardCommentId);
    },
    onContentClick() {
      if (this.editable) {
        this.editText = this.content;
        this.edit = true;
        this.$nextTick(() => {
          this.$refs.inputAnswer.onFocus();
        });
      }
    },
    onCancelEdit() {
      this.edit = false;
    },
    async onSaveEdit() {
      await this.$validate(this.$refs.validator);
      this.$emit('edit', { boardWriteId: this.boardWriteId, boardCommentId: this.boardCommentId, content: this.editText });
    },
    onConsult() {
      const route = this.getCoachPageRoute(this.a?.coach?.coachId);
      this.$router.push(route);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[question-answer] { .br(16);.bgc(#f5f5f7);.p(32, 32, 32, 32);
  > .top {.flex;}
  > .top > .coach-info {flex-grow: 1;.ml(8);.mr(12)}
  > .top > .coach-info > .coach-nickname {.bold;.noto;.fs(18);.lh(26.64);}
  > .top > .coach-info > .coach-rank { .c(@c-text-gray);.fs(14);.lh(20.72);.regular;.noto; }
  > .top > [avatar] { .wh(50, 50);.br(12);}
  > .content {.mt(32);.fs(14);.lh(21);.c(@c-title-black);.noto;white-space: pre-line;.max-w(950); text-overflow: ellipsis;}
  > .bottom { .flex;.flex-ai(center);.mt(20);.fs(12);.lh(17.76);}
  > .bottom > .created-time {.c(@c-text-dark);flex-grow: 1;}
  > .bottom > .likes { .flex;.flex-ai(center);
    &.hasLiked { .c(#2c81ff)}
    &:not(.hasLiked) {.c(@c-text-dark)}
    > span {.ml(8)}
  }
  &.shared {.bgc(#fff)}
  &.no-share {.o(.4)}
  [input-answer] {.mt(32);.mb(20);}
  @media (@tp-down) {
    .p(32, 20, 32, 20);
    > .bottom > .likes > .description {.hide;}
  }
  @media (@ml-down) {
    > .top > .coach-info > .coach-nickname {.block; .max-w(180); .ellipsis;}
  }
}
</style>
