<template>
  <div coach-question>
    <div class="container">
      <div class="container-left">
        <button-list @click="onBackToList" />
        <question-detail :question="qi" @share="onShare" @scrap="onScrap" @cancel-scrap="onCancelScrap" :is-coach="isCoach" />
        <div class="answer-control">
          <p class="answer-count">
            <span>답변</span>
            <em>{{ answerCount }}</em>
            <span>건</span>
          </p>
          <simple-tabs v-model="orderBy">
            <simple-tab key="createdDatetime" class="filter-tab">{{ $t('_.BT_SORT_BY_CREATED_ANSWER') }}</simple-tab>
            <simple-tab key="like" class="filter-tab">{{ $t('_.BT_SORT_BY_LIKES_ANSWER') }}</simple-tab>
          </simple-tabs>
        </div>
        <client-only>
          <div class="coach-control" v-if="isCoach">
            <avatar :defaultImgType="'fruit'" :info="myInfo" userProfile />
            <validation-observer ref="validator" class="coach-answer-input">
              <validation-provider rules="required|lengthMin:10|lengthMax:2000" name="questionAnswer" v-slot="{errors}">
                <input-answer v-model="answerText" @save="onSaveAnswer" @cancel="onCancelAnswer" :class="{error:errors.length >= 1}" :disabled="hasMyAnswer" />
              </validation-provider>
            </validation-observer>
          </div>
        </client-only>
        <no-content alt="eyes" src="/img/coaching/seeking-eyes.svg" v-if="noAnswer">
          {{ $t('_.MC_NO_REPLY_QUESTION') }}
        </no-content>
        <question-answer v-for="answer in answerProps"
          :key="answer.key"
          @share="onShareAnswer"
          @like="onLikeAnswer"
          @unlike="onUnlikeAnswer"
          @edit="onEditAnswer" v-bind="answer" ref="answer" />
        <area-more v-show="hasNextAnswer" @click="onAnswerNext" />
      </div>
      <div class="container-right" v-show="matchedMedia === 'TL' || matchedMediaDevice === 'D'">
        <discord-button />
        <client-only>
          <coach-list />
        </client-only>
      </div>
    </div>
  </div>
</template>

<script>
import cursorFetcher from '@shared/mixins/cursorFetcher';
import Avatar from '@shared/components/common/Avatar.vue';
import QuestionDetail from '@/views/components/coaching/question-detail/QuestionDetail.vue';
import QuestionAnswer from '@/views/components/coaching/question-detail/QuestionAnswer.vue';
import SimpleTabs from '@/views/components/coaching/SimpleTabs.vue';
import SimpleTab from '@/views/components/coaching/SimpleTab.vue';
import AreaMore from '@/views/components/coaching/question/AreaMore.vue';
import ButtonList from '@/views/components/coaching/question-detail/ButtonList.vue';
import coach from '@/mixins/coach';
import NoContent from '@/views/components/coaching/question-detail/NoContent.vue';
import InputAnswer from '@/views/components/coaching/question-detail/InputAnswer.vue';
import CoachList from '@/views/components/coaching/coachList/CoachList.vue';
import DiscordButton from '@/views/components/coaching/DiscordButton.vue';

export default {
  name: 'CoachQuestion',
  lexicon: 'coaching',
  components: { DiscordButton, CoachList, InputAnswer, Avatar, NoContent, ButtonList, AreaMore, SimpleTab, SimpleTabs, QuestionAnswer, QuestionDetail },
  data: () => ({
    questionInfo: null,
    answerInfo: null,
    roles: null,
    gameInfo: null,
    myInfo: /** @type{MyInfo} */ null,
    answerText: '',
  }),
  metaInfo() {
    return {
      title: this.$t('meta.coachQuestion.title'),
      meta: [
        { vmid: 'title', content: this.$t('meta.coachQuestion.title') },
        { vmid: 'description', content: this.$t('meta.coachQuestion.description') },
        { vmid: 'url', content: this.$t('meta.coachQuestion.url') },
        { vmid: 'site_name', content: this.$t('meta.siteName') },
        { vmid: 'type', content: this.$t('meta.type') },
        { vmid: 'image', content: this.$t('meta.img') },
        { vmid: 'keywords', content: this.$t('meta.coachQuestion.keywords') },
      ],
    };
  },
  mixins: [cursorFetcher({
    answerInfo: ({ services, cursor, orderBy, boardWriteId }) => services.coaching.getAnswers({ boardWriteId, orderBy, cursor, size: 3 }),
  }), coach],
  computed: {
    qi() {
      return /** @type{Question} */ this.questionInfo || {};
    },
    ai() {
      return /** @type {PagedAnswers} */ this.answerInfo || {};
    },
    answers() {
      return /** @type{Answer[]} */this.ai.items || [];
    },
    orderBy: {
      get() {
        return this.$route?.query?.['order-by'] || 'createdDatetime';
      },
      set(orderBy) {
        const query = this.getMergedQuery({ 'order-by': orderBy });
        this.$router.push({ query });
      },
    },
    answerCount() {
      const answerCount = this.ai?.totalCount || 0;
      return `${answerCount}`;
    },
    hasNextAnswer() {
      return this.ai?.hasNext || false;
    },
    isCoach() {
      return (this.roles || []).includes('coach');
    },
    hasMyAnswer() {
      return this.qi?.hasMyAnswer || false;
    },
    gameId() {
      return this.$route.params.gameId || '';
    },
    noAnswer() {
      return this.ai?.totalCount === 0;
    },
    coachId() {
      // 링크를 타고 들어올 경우 강조할 코치
      return this.$route?.query?.coachId ?? '';
    },
    answerProps() {
      return this.answers.map(answer => {
        const a = /** @type{Answer} */ answer;
        let shared = null; // null = 공유된 답변 없음
        if (this.sharedBoardCommentId !== '') shared = a?.boardCommentId === this.sharedBoardCommentId;
        if (this.coachId !== '') shared = a?.coach?.coachId === this.coachId;
        const editable = this.isCoach && a?.coach?.user?.userId === this.myInfo?.id;
        const key = a?.boardCommentId;
        return { answer: a, shared, editable, key };
      });
    },
    isLogin() {
      return this.$store?.getters?.['auth/isLogin'] ?? false;
    },
  },
  methods: {
    onAnswerNext() {
      return this.fetchMore(this.answerInfo);
    },
    onBackToList() {
      const coachId = this.$route.query?.coachId;
      if (coachId) this.routeName('CoachDetailActivity', { coachId, tab: 'activity' });
      else this.routeName('CoachQuestions', { gameId: 'lol' });
    },
    async onScrap(boardWriteId) {
      if (!this.isLogin) {
        await this.$services.auth.oAuthLogin(true);
        return;
      }
      this.questionInfo = await this.$services.coaching.scrapQuestion({ boardWriteId, gameId: this.gameId });
    },
    async onCancelScrap(boardWriteId) {
      this.questionInfo = await this.$services.coaching.cancelScrapQuestion({ boardWriteId, gameId: this.gameId });
    },
    onShare(boardWriteId) {
      this.shareQuestion(boardWriteId);
    },
    onShareAnswer(boardWriteId, boardCommentId) {
      this.shareAnswer(boardWriteId, boardCommentId);
    },
    /**
     * @param {Answer} newAnswer
     */
    updateAnswer(newAnswer) {
      const boardCommentId = newAnswer?.boardCommentId || '';
      const answerIndex = this.answerInfo.items.findIndex(a => a?.boardCommentId === boardCommentId);
      if (answerIndex === -1) return;
      this.$set(this.answerInfo.items, answerIndex, newAnswer);
    },
    async onLikeAnswer(boardWriteId, boardCommentId) {
      if (!this.isLogin) {
        await this.$services.auth.oAuthLogin(true);
        return;
      }
      const answer = await this.$services.coaching.likeAnswer({ boardWriteId, boardCommentId });
      this.updateAnswer(answer);
    },
    async onUnlikeAnswer(boardWriteId, boardCommentId) {
      const answer = await this.$services.coaching.unlikeAnswer({ boardWriteId, boardCommentId });
      this.updateAnswer(answer);
    },
    onCancelAnswer() {
      this.answerText = '';
    },
    async onSaveAnswer() {
      await this.$validate(this.$refs.validator);
      const { boardWriteId } = this.$route.params;
      const answer = await this.$services.coaching.postAnswer({ boardWriteId, content: this.answerText });
      this.$set(this.answerInfo, 'items', [...this.answerInfo.items, answer]);
      this.$set(this.answerInfo, 'totalCount', this.answerInfo.totalCount + 1);
      this.$set(this.questionInfo, 'hasMyAnswer', true);
      this.answerText = '';
    },
    async onEditAnswer({ boardWriteId, boardCommentId, content }) {
      const answer = await this.$services.coaching.editAnswer({ boardWriteId, boardCommentId, content });
      const refIndex = this.answers.findIndex(a => a?.boardCommentId === boardCommentId);
      if (refIndex !== -1) this.$refs.answer[refIndex].onCancelEdit();
      this.updateAnswer(answer);
    },
    async resetData() {
      this.gameInfo = { id: this.$route.params.gameId.toLowerCase(), ipList: this.$store.state.info.games };
      const { myInfo } = this.$store.state.auth;
      this.myInfo = myInfo;
      const boardWriteId = this.$route.params.boardWriteId || '';
      const gameId = this.$route.params?.gameId.toLowerCase();
      const questionTask = this.$services.coaching.getQuestion({ gameId, boardWriteId });
      const orderBy = this.$route.query?.['order-by'] || 'createdDatetime';
      [this.questionInfo, this.answerInfo] = await Promise.all([questionTask, this.fetchInit('answerInfo', { orderBy, boardWriteId })]);
      this.roles = this.$store.getters['auth/roles'];
    },
  },
  watch: {
    $route: 'resetData',
  },
  async mounted() {
    await this.resetData();
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-question] {.bgc(@c-base-gray);}

[coach-question] > .container { .max-w(@screen-ds-max);.m(0, auto);.pb(120);.pt(40);
  .answer-control {.flex;.mt(32);.mb(12)}
  .answer-control > .answer-count {flex-grow: 1;.fs(16);.lh(24);.bold;
    > span {.c(#000);}
    > em {.c(@c-blue);}
  }
  [button-list] {.mb(20);}
  [question-answer] + [question-answer] {.mt(12)}
  [area-more] {.mt(20);}
  .coach-control { .flex;
    .coach-answer-input {flex-grow: 1;.ml(12);.mb(20);}
    [avatar] {.wh(40, 40);.br(12);}
  }
  @media (@ds-down) {
    .m(0, 20);
  }
  @media (@ds-up) {
    .flex;
    .container-left {flex: 1;.mr(30)}
  }
}
</style>
